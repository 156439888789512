<template>
  <v-row>
    <v-col cols="12">
        <modifier-card> </modifier-card> 
    </v-col>
  </v-row>
</template>
<script>
import ModifierCard from "@/components/product/ModifierCard";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Product Modifier",
  },

  components: {
    ModifierCard
  },

  data() {
    return {
    };
  },
};
</script>